import React from 'react'
import { Card as CardComponent } from 'theme-ui'
import { Layout, Main, Stack } from '@layout'
import CardList from '@components/CardList'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import AuthorExpanded from '@widgets/AuthorExpanded'
import NewsletterExpanded from '@widgets/NewsletterExpanded'
import { PostBody, PostComments } from '@widgets/Post'
import CardPostInfo from '@elegantstack/flow-ui-components/src/Card/Card.Post.Info'
import CardPost from '@elegantstack/flow-ui-components/src/Card/Card.Post'
import Places from '@elegantstack/flow-ui-components/src/Places'

const BestCountryExchange = ({
  data: {
    bestCountryExchange,
    bestExchanges,
    bestCountryExchanges,
    tagCategoryPosts,
    tagPosts,
    categoryPosts
  },
  ...props
}) => {
  const relatedPosts = [
    ...(tagCategoryPosts ? tagCategoryPosts.nodes : []),
    ...(tagPosts ? tagPosts.nodes : []),
    ...(categoryPosts ? categoryPosts.nodes : [])
  ]

  if (bestCountryExchange.exchanges) {
    bestCountryExchange.exchanges.map(exchange => {
      const exchangeSlug = exchange.exchange.slug
      exchange.exchange = bestCountryExchanges.nodes.find(
        item => item.slug === exchangeSlug
      )
      return exchange
    })
  }

  const { pageContext: { services = {}, siteUrl } = {} } = props
  bestCountryExchange.location = props.location
  return (
    <Layout {...props}>
      <Seo {...bestCountryExchange} siteUrl={siteUrl} />
      <Divider />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <Main>
          <CardPost {...bestCountryExchange} omitExcerpt />
        </Main>
      </Stack>
      {bestCountryExchange.exchanges && (
        <Stack effectProps={{ effect: 'fadeInDown' }}>
          <Places {...{ nodes: bestCountryExchange.exchanges }} />
        </Stack>
      )}
      <Stack effectProps={{ fraction: 0 }}>
        <Main>
          {bestCountryExchange.exchanges &&
            bestCountryExchange.exchanges.map(item => (
              <React.Fragment key={item.exchange.id}>
                <CardComponent variant='paper-lg'>
                  {item.exchange.body && (
                    <PostBody
                      {...item.exchange}
                      rank={item.rank}
                      isRank={true}
                    />
                  )}
                </CardComponent>
                {(item.exchange.website ||
                  item.exchange.transactionFees ||
                  item.exchange.currenciesAvailable) && (
                  <CardComponent sx={{ mt: '3', p: '4' }}>
                    <CardPostInfo post={item.exchange} />
                  </CardComponent>
                )}
                <Divider space={3} />
              </React.Fragment>
            ))}

          {bestCountryExchange.author && (
            <AuthorExpanded author={bestCountryExchange.author} />
          )}

          {services.disqus && (
            <>
              <Divider />
              <PostComments {...bestCountryExchange} />
            </>
          )}

          {bestCountryExchange.category && (
            <>
              <Divider />
              <CardList
                nodes={relatedPosts}
                variant={['horizontal-md']}
                columns={[1, 2, 3, 3]}
                limit={6}
                title='Related Posts'
                distinct
              />
            </>
          )}

          {services.mailchimp && (
            <>
              <Divider />
              <NewsletterExpanded simple />
            </>
          )}

          {bestCountryExchange.body && (
            <>
              <Divider space={1} />
              <CardComponent variant='paper-lg'>
                <PostBody {...bestCountryExchange} />
              </CardComponent>
            </>
          )}
        </Main>
      </Stack>
    </Layout>
  )
}

export default BestCountryExchange
