/** @jsx jsx */
import { jsx } from 'theme-ui'
import React from 'react'
import { Text, Link, Image, Card, Flex, Box } from 'theme-ui'
import Star from '../Star'
import getImageVariant from '@components/utils/getImageVariant'

const styles = {
  image: {
    maxWidth: '150px',
    '@media screen and (max-width: 900px)': {
      maxWidth: '75px'
    },
  },
  mobileList: {
    display: 'none',
    '@media screen and (max-width: 767px)': {
      display: 'block'
    }
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    borderSpacing: '0px',
    '@media screen and (max-width: 767px)': {
      display: 'none'
    }
  },
  tableHeading: {
    'textAlign': 'center',
    'fontSize': '13px',
    'fontWeight': 'bold',
    '& td': {
      padding: '5px 0',
    },
  },
  tableBody: {
    'textAlign': 'center',
    'fontSize': '15px',
    'fontWeight': 'bold',
  },
  tableBodyTr: {
    padding: '10px 0px',
    borderBottom: '1px solid #e4e4e4',
    '&:last-child': {
      borderBottom: 'none'
    },
    '&:hover': {
      outline: '4px solid #667eea',
      outlineOffset: '-3px',
      '&:first-of-type': {
        outlineColor: '#ffd700',
        '& td:first-of-type::before': {
          background: '#ffd700'
        }
      },
      '&:nth-child(2)': {
        outlineColor: '#c0c0c0',
        '& td:first-of-type::before': {
          background: '#c0c0c0'
        }
      },
      '&:nth-child(3)': {
        outlineColor: '#cd7f32',
        '& td:first-of-type::before': {
          background: '#cd7f32'
        }
      },
      '& td': {
        position: 'relative',
        ':first-of-type::before': {
          content: 'attr(data-index)',
          position: 'absolute',
          left: '0px',
          fontSize: '16px',
          fontWeight: '500',
          padding: '10px 10px 10px 4px',
          background: '#667eea',
          borderTopRightRadius: '30px',
          borderBottomRightRadius: '30px',
          color: '#fff',
          top: 'calc(50% - 24px)',
        },
      }
    },
  },
  visitSite: {
    padding: '14px 19px',
    background: '#667eea',
    color: '#fff !important',
    textTransform: 'uppercase',
    borderRadius: '4rem',
    cursor: 'pointer',
    '&:hover': {
      color: '#fff'
    },
    '@media screen and (max-width: 900px)': {
      fontSize: '11px',
      padding: '.75rem 1rem',
      borderRadius: '0',
      display: 'block',
      textAlign: 'center',
    },
  },
  rating: {
    fontSize: '21px',
    '@media screen and (max-width: 900px)': {
      fontSize: '16px',
    },
  },
  ourReview: {
    '@media screen and (max-width: 900px)': {
      fontSize: '14px',
      marginLeft: '10px'
    },
  }
}


const Places = ({ nodes }) => {
  return (
    <Card sx={{ width: '100%', mb: '3' }}>
      <table sx={styles.table}>
        <thead sx={styles.tableHeading}>
          <tr>
            <td>Provider</td>
            <td>Bank Transfer</td>
            <td>Visa/Mastercard</td>
            <td>Available Crypto</td>
            <td>Our Score</td>
            <td />
          </tr>
        </thead>
        <tbody sx={styles.tableBody}>
          {nodes.map((item, idx) => {
              const provider = getImageVariant(item.exchange.logo, 'logo')
              return (<tr key={idx} sx={styles.tableBodyTr}>
                <td data-index={`#${(idx + 1)}`} sx={styles.tableBodyTd}>
                  {provider && <Image sx={styles.image} alt={item.exchange.title} src={provider.images.fallback.src} />}
                </td>
                <td data-label="Bank Transfer">{item.exchange.bankTransfer ? 'Yes' : 'No'}</td>
                <td data-label="Visa/Mastercard">{item.exchange.cards ? 'Yes' : 'No'}</td>
                <td data-label="Available Crypto" sx={{ fontWeight: '500' }}>
                  {item.exchange.availableCrypto && <Text>{item.exchange.availableCrypto}</Text>}
                </td>
                <td data-label="Our Score">
                  <Flex sx={{
                    flexDirection: 'column',
                    alignItems: 'center'
                  }}>
                    {item.score &&
                    <>
                      <Text sx={styles.rating}>{item.score}</Text>
                      <Star rating={item.score} />
                    </>}
                    {item.exchange.ourReview &&
                    <Link target="_blank" sx={styles.ourReview} href={item.exchange.ourReview.slug}>Read more</Link>}
                  </Flex>
                </td>
                <td data-label="Visit Site">
                  {item.exchange.referralLink &&
                  <Link target="_blank" sx={styles.visitSite} href={item.exchange.referralLink}>Visit site</Link>}
                </td>
              </tr>)
            }
          )}
        </tbody>
      </table>
      <Box sx={styles.mobileList}>
        {nodes.map((item, idx) => {
          const provider = getImageVariant(item.exchange.logo, 'logo')
          return (
            <Flex key={idx} sx={{
              borderBottom: '3px solid #e4e4e4',
              marginBottom: '15px',
              flexDirection: 'column',
              position: 'relative',
              '&:first-of-type': {
                outlineColor: '#ffd700',
                '.place': {
                  background: '#ffd700'
                }
              },
              '&:nth-child(2)': {
                '.place': {
                  background: '#c0c0c0'
                }
              },
              '&:nth-child(3)': {
                '.place': {
                  background: '#cd7f32'
                }
              },
            }}>
              <Box className="place" sx={{
                position: 'absolute',
                left: '0px',
                fontSize: '14px',
                fontWeight: '500',
                padding: '10px 10px 10px 4px',
                background: '#667eea',
                borderTopRightRadius: '30px',
                borderBottomRightRadius: '30px',
                color: '#fff',
                top: '24px',
              }}>#{idx + 1}</Box>
              <Flex sx={{ '@media screen and (max-width: 370px)': { flexDirection: 'column' } }}>
                <Flex sx={{
                  padding: '15px 0 0 15px',
                  flex: '1 1 30%',
                  alignItems: 'flex-start',
                  flexDirection: 'column',
                  '@media screen and (max-width: 500px)': {
                    flex: '1 1 45%',
                  }
                }}>
                  {provider && <Image sx={{
                    width: '100%'
                  }} alt={item.title} src={provider.images.fallback.src} />}
                  {item.score && <Flex sx={{ alignItems: 'center', flexDirection: 'column', width: '100%' }}>
                    <Star rating={item.score} />
                  </Flex>}
                </Flex>
                <Flex sx={{ padding: '15px', flex: '1 1 70%' }}>
                  <Flex sx={{ flexDirection: 'row', width: '100%' }}>
                    <Flex sx={{ flexDirection: 'column', flex: '1 1 65%', justifyContent: 'space-between' }}>
                      <Flex sx={{ flexDirection: 'column' }}>
                        <Box sx={{ marginRight: '15px' }}>Bank Transfer: {item.exchange.bankTransfer ? 'Yes' : 'No'}</Box>
                        <Box sx={{ marginRight: '15px' }}>Visa/Mastercard: {item.exchange.cards ? 'Yes' : 'No'}</Box>
                        {item.exchange.availableCrypto && <Box>Available Crypto: {item.exchange.availableCrypto}</Box>}
                        <Box>Score: {item.score}
                          {item.exchange.ourReview &&
                          <Link target="_blank" sx={styles.ourReview} href={item.exchange.ourReview.slug}>Read more</Link>}
                        </Box>
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
              {item.exchange.referralLink && <Box sx={{ width: '100%' }}>
                <Link target="_blank" sx={styles.visitSite} href={item.exchange.referralLink}>Visit site</Link>
              </Box>}
            </Flex>
          )
        })}
        <Box>

        </Box>
      </Box>
    </Card>
  )
}

export default Places
