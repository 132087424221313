import { graphql } from 'gatsby'
import BestCountryExchangePage from '../containers/BestCountryExchange'

export default BestCountryExchangePage

export const pageQuery = graphql`
  query BestCountryExchangePageQuery(
    $id: String!
    $previousId: String
    $nextId: String
    $exchanges: [String]
  ) {
    bestCountryExchange: bestCountryExchange(id: { eq: $id }) {
      ...BestCountryExchangeInformation
      ...BestCountryExchangeThumbnailRegular
    }

    bestCountryExchanges: allExchange(
      filter: {
        private: { ne: true }
        draft: {ne: true}
      }
      sort: { fields: [rank], order: ASC }
      limit: 10000
    ) {
      nodes {
        ...ExchangeInformation
        ...ExchangeThumbnailRegular
        ...ExchangeLogo
      }
    }

    bestExchanges: allExchange(
     filter: {
       id: { in: $exchanges }
       private: { ne: true }
       draft: {ne: true}
     }
     sort: { fields: [rank], order: ASC }
     limit: 10000
   ) {
     nodes {
       ...ExchangeInformation
       ...ExchangeThumbnailRegular
       ...ExchangeLogo
     }
   }

    previous: bestCountryExchange(id: { eq: $previousId }) {
      id
      slug
      title
    }

    next: bestCountryExchange(id: { eq: $nextId }) {
      id
      title
    }
  }
`
